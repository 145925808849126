.loa-sec{
    background-color:#525659 !important;
	padding: 60px 0;


    .pdf-section{
        background-color: #fff;
        -webkit-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.51);
        -moz-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.51);
        box-shadow: 0px  4px 7px 0px rgba(0,0,0,0.51);
        min-height: 500px;
        padding:60px !important;
        margin-bottom: 15px;
        .logo{
            width:130px;
            }
            .headtxt{
                p{
                    font-size:13px;
                    color:#000;
                
                    }
                }
    }
    
    .w25px{
        width: 25px;
      }
      .inp-cust{
        width:24px;
        height:34px;
        border: 0px;
        outline: none;
        box-shadow: none;
        text-align: center;
        border: inset 1px #f9f9f9;
        margin: 0 auto 0 3px;
      }
      .arrow-col{
        padding: 0px !important;
      }
      .arrow-div{
        position: relative;
        border: solid 1px #ff0b0b;
        padding: 5px;
        border-radius: 3px;
        background-color: #ff0b0b;
        color:#fff;
        margin-bottom: 15px;
        display: inline-block;
        -webkit-animation: mover 1s infinite alternate;
        animation: mover 1s infinite alternate;
      }
      .arrow-red{
        width: 38px;
        height: 49px;
        position: absolute;
        top: -28px;
        left: -21px;
    
      }
      @-webkit-keyframes mover {
        0% {
            transform: translate(0%, 0);
        }
        100% {
            transform: translate(0%, -20px);
        }
    }
    
    @keyframes mover {
        0% {
            transform: translate(0%, 0);
        }
        100% {
            transform: translate(0%, -20px);
        }
    }

    .brdr-btm{
        border-bottom: 1px solid #000;
    }

    .brdr-lf{
        border-left: 1px solid #000;
    }

    .brdr-bl{
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
    }
    
    .content_txt{
        h2{
            font-size:26px;
            color:#000;
            margin-bottom:15px;
            font-weight: bold;
            margin-top: 20px;
        }
        p{
            font-size: 12px;
            color:#000;
            font-weight: normal;
            margin: 0px;
        }
        h3{
            font-size: 15px;
            color:#000;
            font-weight: normal;
            margin: 5px 0;
        }
        h4{
            font-size: 14px;
            color:#000;
            font-weight:600;
            margin: 5px 0;
            text-align: center;
        }
        .text_small{
        
            p{
                font-size: 10px !important;
                color:#000;
                font-weight: normal;
                margin: 0px;
                line-height: 11px;
    
            }
        }
        .signImg{
            width:100%;
            height: 100px;
        }
    }
    
    
    .content_txt_terms{
        h2{
            font-size:20px;
            color:#000;
            margin-bottom:15px;
            font-weight: bold;
            margin-top: 20px;
        }
        p{
            font-size: 14px;
            color:#000;
            font-weight:600;
            margin: 0px 0 15px;
        }
        h3{
            font-size: 17px;
            color:#000;
            font-weight: 800;
            margin: 5px 0 15px;
        }
        h4{
            font-size: 14px;
            color:#000;
            font-weight:600;
            margin:10px 0;
        }
    
        ul{
            li{
                font-size: 14px;
                color:#000;
                font-weight:600;
                margin-bottom: 15px;
            }
        }
    
    }
    .bor-box{
        border: solid 1px #000;
    }
    .pl75{
        padding-left: 75px !important;
    }
    .pt15{
        padding-top: 15px;
    }
    .arrowbg{
        background: url(/assets/img/ar_bg.png) no-repeat;
        
    }
    .grey_bg{
        background-color: #e6e7e9;
    }
    .redtext{
        color: red !important;
    }
    .sign_div{
        width: 100%;
        border: solid 1px #000;
        padding: 30px 15px !important;
        font-size: 13x !important;
        color: #000;
        font-weight: normal;
        margin: 0px;
    }
    
    .client_div{
        width: 100%;
        border: solid 1px #376289;
        padding: 15px !important;
        margin: 0px;
        border-radius: 45px;
    }
    .w90{
        width: 90px;
    }
    .w35{
        width: 35px;
        font-weight: bold;
    }
    .w100{
        width:100px;
    }
    .h100{
        height:100px;
    }
    .h75{
            height:75px;
    }
    .pad5{
        padding: 5px;
    }
    
    .padb5{
        padding-bottom: 5px !important;
    }
    .padb10{
        padding-bottom: 10px;
    }
    .mtop25{
        margin-top: 25px;
    }
    .mtb25{
        margin: 20px 0;
    }
    .mtb10{
        margin: 10px 0;
    }
    .mb0{
        margin-bottom: 0px !important;
    }
    .table-bordered td {
        border: 1px solid #808080 !important;
    }
    .bor-r{
        border-right: 1px solid #808080 !important;
    }
    .name-div{
        width: 100%;
        border-bottom: solid 1px #000;
        padding: 40px 0 !important;
        font-size: 13x !important;
        color:#000;
        font-weight: normal;
        margin: 0px;
    
    }
    
    .container {
        max-width: 794px !important;
        width: 794px !important;
    }
    .button{
        background: #00b63d;
        padding: 10px 20px;
        border-radius: 5px;
        min-width: 200px;
        display: inline-block;
        text-align: center;
        font-size: 17px;
        -webkit-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.51);
        -moz-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.51);
        box-shadow: 0px  4px 7px 0px rgba(0,0,0,0.51);
        font-weight: 800;
        cursor: pointer;
        // position: relative;
        color: #FFFFFF !important;
        border: solid 1px #16ef5f;
        text-decoration: none !important;
        left: 0;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    
    
    
    @media (min-width: 1200px){
        .container {
                max-width: 1070px !important;
        }
        }
    @media (min-width: 992px) {
        
    }
    @media screen and (max-width: 991px) and (min-width: 768px){
        
    
    
    }
    
    @media screen and (max-width:768px) {
    
    }
    
    @media screen and (max-width:374px) and (min-width:320px){
    }
    
    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    
    .animated-effect {
        -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        box-shadow: 0 0 0 0 #f7a900;
    }
    
    @-webkit-keyframes pulse {
        to {
            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
    }
    
    @-moz-keyframes pulse {
        to {
            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
    }
    
    @-ms-keyframes pulse {
        to {
            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
    }
    
    @keyframes pulse {
        to {
            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
    }
}
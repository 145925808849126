/*
	*******************************************
	Template Name: Allegiant
	Author: Amal k
	Date:10-02-2023
	Template By: Vandalay Designs
	Copyright 2023-2024 Vandalay Business Solution

	* This file contains the styling for the Allegiant, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/

// 	============================================
// ==== T A B L E   O F   C O N T E N T S =====
// ============================================
// 01 - Sass Variables
// 02 - QUESTIONIRE BODY
// 03 - ANIMATION CLASSES
// 04 - ANIMATION
// 05 - MEDIA QUERY

/*================================================
01 - Sass Variables
==================================================*/
$background_color_2: #2f3996;
$color_1: #015882;

/*================================================
02 - QUESTIONIRE BODY 
==================================================*/

body {
  font-family: "Arial", sans-serif !important;
}

.AD_PI_V4_B_Layout {
  .SECT {
    .form_part {
      .bannersubtext {
        h3 {
          font-size: 1.2rem;
          color: $color_1;
        }
        p {
          color: black;
          text-align: start;
        }
      }
    }
  }

  .guarantsection {
    padding: 80px 0;
    background: $color_1;
    color: white;
    position: relative;
    h2 {
      font-size: 2.8rem;
    }
    .img-wrap {
      position: relative;
      img {
        position: absolute;
        bottom: -80px;
      }
    }
  }
  .frequentlysection {
    padding: 80px 0;
    .title {
      font-size: 2.5rem;
      color: $color_1;
    }

    .accordion-item {
      border: 0;
      margin-bottom: 10px;
      .accordion-header {
        border: 1px solid $color_1;
        border-radius: 10px;
        color: white;

        .accordion-button {
          background: transparent;
          border-radius: 10px;
          font-weight: bold;
          font-size: 1.2rem;
          background: $color_1;
          color: white;
          box-shadow: initial;
          &:not(.collapsed)::after {
            background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>");
          }
          &.collapsed {
            background: transparent;
            color: $color_1;
          }
        }
      }
    }
  }
  .sectionreview {
    // background: #eee;
    border-bottom: 1px solid #ddd;

    .review-box {
      max-width: 1000px;
      margin: 0 auto;
      .text1 {
        font-size: 1.1rem;
        &.text2 {
          margin-top: 33px;
        }
      }
    }

    .review-header {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .rating-star-div {
        width: 108px;
        height: 20px;

        img {
          width: 100%;
        }
      }

      .date-div {
        flex-grow: 1;
        text-align: right;
      }
    }
  }

  // MEDIA QUERY

  @media screen and (max-width: 1200px) {
    .guarantsection {
      .img-wrap {
        img {
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    .guarantsection {
      padding: 40px 0 0 0;

      h2 {
        font-size: 2rem;
      }
      .img-wrap {
        img {
          max-width: 100%;
          position: initial;
        }
      }
    }

    .frequentlysection {
      padding: 40px 0;
      .title {
        font-size: 2rem;
      }
    }
  }
  @media screen and (max-width: 540px) {
    .guarantsection {
      h2 {
        font-size: 1.5rem;
      }
    }

    .frequentlysection {
      .title {
        font-size: 1.5rem;
      }
      .accordion-item {
        .accordion-header {
          .accordion-button {
            font-size: 1rem;
          }
        }
      }
    }

    .sectionreview {
      .review-box {
        .text1 {
          font-size: 1rem;
        }
        .trustlogo {
          height: 40px;
          margin-bottom: 10px;
        }
        .reviewstar {
          height: 30px;
        }
      }
    }
  }
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.AD_PI_V4_B {
  /*
	*******************************************
	Template Name: Allegiant
	Author: Amal k
	Date:10-02-2023
	Template By: Vandalay Designs
	Copyright 2023-2024 Vandalay Business Solution

	* This file contains the styling for the Allegiant, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/

  // 	============================================
  // ==== T A B L E   O F   C O N T E N T S =====
  // ============================================
  // 01 - Sass Variables
  // 02 - QUESTIONIRE BODY
  // 03 - ANIMATION CLASSES
  // 04 - ANIMATION
  // 05 - MEDIA QUERY

  /*================================================
01 - Sass Variables
==================================================*/

  margin: 0;
  //font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  $background_color_2: #2f3996;

  $color_1: #015882;

  /*================================================
02 - QUESTIONIRE BODY 
==================================================*/

  body {
    font-family: "Arial", sans-serif !important;
  }
  font-family: "Arial", sans-serif !important;
  header {
    .logo_1 {
      height: 60px;
      margin-top: 0px !important;
    }
  }

  .SECT {
    .form_part {
      //add if needed for V4_B
      .AD_PI_V4_B_Layout {
        .bannersubtext {
          @media screen and (max-width: 767px) {
            .sub-head {
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          @media screen and (max-width: 576px) {
            .sub-head {
              // svg {
              //   margin-left: -1.5rem;
              // }
            }
          }

          h3 {
            font-size: 1.2rem;
            color: $color_1;
          }
          p {
            color: black;
            text-align: start;
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
      }

      width: 80%;
      margin: auto;

      .head-class {
        @media only screen and (min-width: 768px) {
          margin-bottom: 32px !important;
        }
      }

      h1 {
        color: $color_1 !important;
        font-size: 44px;
      }
      h4 {
        color: $color_1 !important;
      }
      p {
        color: #ff0000;
        text-align: center;
        font-size: 14px;
      }
      .arrow_gif {
        text-align: center;
        img {
          height: 20px;
        }
      }

      .icon_option {
        font-weight: 400 !important;
        background-color: #015882 !important;
        color: #ffffff !important;
        // font-size: 16px;
        font-size: 24px;
        line-height: 30px;
        text-decoration: none;
        font-style: normal;
        padding-top: 37px !important;
        padding-bottom: 37px !important;
        padding-left: 10px;
        padding-right: 10px;
        border-width: nullpx !important;
        border-style: none !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        text-align: center;
        width: 100%;
        display: block;
        cursor: pointer;

        @media only screen and (max-width: 576px) {
          padding-top: 13px !important;
          padding-bottom: 13px !important;
        }
      }

      .my-bt {
        font-weight: 500 !important;
        background-color: #015882 !important;
        color: #ffffff !important;
        font-size: 24px;
        line-height: 3px;
        text-decoration: none;
        font-style: normal;
        padding-top: 37px !important;
        padding-bottom: 37px !important;
        border-width: nullpx !important;
        border-style: none !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        text-align: center;
        width: 100%;
        display: block;
        cursor: pointer;
      }
      label input[type="radio"] {
        display: none !important;
      }

      .form-control {
        border-radius: 5px 0 0 5px !important;
      }
      .input-group-text {
        border-radius: 0 5px 5px 0;
      }
      .qstn_1 {
        font-weight: 700;
        // font-size: 30px;
        font-size: 20px;
        color: #4c4b4b;
        margin-bottom: 20px !important;
        line-height: 30px;
      }
      h5 {
        color: #4c4b4b;
      }

      .safe-text {
        color: #bab9b9;
      }

      .back_btn {
        font-size: 20px;
        color: #006ebe !important;
        padding: 10px 0;
        cursor: pointer;
      }
    }
  }

  .progress {
    .bg-grn {
      background-color: #015882;
    }
  }

  .footer_text {
    font-family: "Poppins", sans-serif !important;
    line-height: 1.5;
    background-color: #dfe5ea;
    padding: 30px 0;
    color: #000 !important;
    img {
      height: 80px;
      margin-bottom: 10px;
    }
  }

  footer {
    // font-family: "Poppins",sans-serif!important;
    line-height: 1.5;
    background-color: #e5e5e5;
    padding: 30px 0;
    color: #000 !important;

    img {
      height: 80px;
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
    }
    ul {
      list-style: none;
      display: block;
      padding: 0;
      li {
        display: inline-block;
        padding: 0 5px;
        border-right: 1px solid #000;
        &:last-child {
          border-right: none;
        }
        a {
          color: #000 !important;
          text-decoration: none;
          cursor: pointer;
          font-size: 13px;
        }
      }
    }
  }

  .error_msg {
    color: #fff;
    width: 100%;
    float: left;
    background: red;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 4px !important;
    margin-bottom: 10px;
  }

  .modal {
    background-color: rgba(0, 0, 0, 0.9);
    h5 {
      font-weight: 300;
    }
    .custom-mdal-btn {
      margin: 10px 0 !important;
      font-size: 20px !important;
      width: 35%;
      background-color: #015882 !important;
      border: 0 !important;
      border-bottom: 4px solid #003149 !important;
      font-weight: 300;
      .slide-pad {
        h2 {
          font-weight: 100;
        }
      }
    }

    .bord-b {
      border-bottom: 5px solid #015882;
    }
  }

  // MEDIA QUERY

  @media only screen and (max-width: 576px) {
    .form_part {
      .icon_option {
        margin: auto;
        padding-left: 10px !important;
        padding-right: 10px !important;
        font-size: 23px !important;
        // font-size: 28px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    header {
      .logo_1 {
        height: 60px;
      }
    }
    .SECT {
      .text-consent {
        font-size: 12px !important;
      }

      .form_part {
        width: 100%;
        margin: 0;
        padding-left: 12px;
        padding-right: 12px;
        h1 {
          color: #000000 !important;
        }
        .qstn_1 {
          // font-size: 18px;
          font-size: 20px;
          line-height: 30px;
          margin-top: 0.5rem;
          margin-bottom: 1rem;

          color: #4c4b4b;
        }
        .icon_option {
          // font-size: 9px;
          // width: 86%;
          // margin: auto;
        }
        .form-control {
          border-radius: 5px 0 0 5px !important;
        }
        .input-group-text {
          border-radius: 0 5px 5px 0;
        }
      }
      h1 {
        font-size: 26px !important;
        padding: 2px 15px 2px !important;
      }
      h2 {
        font-size: 20px !important;
      }
      .sm-device-txt {
        width: 100%;
        padding: 10px 15px 25px 15px;
      }
    }

    footer {
      padding: 10px 0 !important;
      img {
        height: 60px;
      }
      p {
        font-size: 9px;
      }
      ul {
        li {
          a {
            font-size: 9px;
          }
        }
      }
    }

    .progress {
      margin: 0.5rem 1.5rem !important;
    }
  }
  .progress {
    margin: 1.5rem;
    height: 20px;
  }
  .progress-bar {
    background-color: #015882;
  }
}
.AD_PI_V4_B .SECT .form_part .icon_option.active {
  background-color: #003149 !important;
}

// Option A: Include all of Bootstrap

//@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/bootstrap";

// Custom.scss

@import "./modules/chat.scss";
@import "./modules/circlesinitiative.scss";
@import "./modules/custom.scss";

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css");

/*
	*******************************************
	Template Name: personal-injury
	Author: Shinto kunjumon
	Date: 28-06-2022
	Template By: Vandalay Designs
	Copyright 2020-2021 Vandalay Business Solution

	* This file contains the styling for https://the-compensation-experts.co.uk/, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************

	============================================
	==== T A B L E   O F   C O N T E N T S =====
	============================================
	01 - Sass Variables
	02 - Sass Mixins
	03 - BODY 
	04 - HEADER 
	05 - SECTION 
	06 - FOOTER 
	07 - MODAL 
	08 - MEDIA QUERY

	============================================
	============================================

	*/

//@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&family=Ubuntu:ital,wght@0,300;0,400;1,300;1,400&display=swap");
/*================================================
	01 - Sass Variables
	==================================================*/

// =======background colors========

$background_color_1: #097dc0;
$background_color_2: #03588b;
$background_color_3: #7d519d;
$background_color_4: #ffb100;

// ======font styles===============

$color_1: #ffffff;
$color_2: #000;
$color_3: #303291;

$font_size_1: 14px;
$font_size_2: 22px;
$font_size_3: 28px;
$font_size_4: 26px;
$font_size_5: 18px;
$font_size_6: 15px;
$font_size_7: 17px;
$font_size_8: 20px;

$font-bold: bold;
$font-w600: 600;
$font-w700: 700;

// ======border===========

/*================================================
	02 - Sass Mixins
	==================================================*/

/*================================================
	03 - BODY STYLES
	==================================================*/

$font_family_1: "Poppins", sans-serif;
$font_family_2: "Ubuntu", sans-serif;
$font_family_3: "Open Sans", sans-serif;
body {
  font-family: $font_family_1 !important;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  background-color: #e5e5e5;
}

.personal_injury {
  scroll-behavior: smooth;
  a {
    cursor: pointer;
  }

  /*================================================
	04 -bannersection
	==================================================*/

  .bannersec {
    padding: 15px 0;
    img {
      margin-bottom: 30px;
      height: 100px;
    }
    h1 {
      font-size: 36px;
      font-family: $font_family_2;
      margin: 20px 25px;
    }
    h3 {
      font-size: 21px;
      font-weight: 700;
      color: $color_2;
      width: 60%;
      padding: 0 0px;
      margin: 0 auto;
      line-height: 26px;
      font-family: $font_family_3;
    }

    // formsection

    .form-section {
      padding: 20px;
      background: $background_color_2;
      color: $color_1;
      margin-left: 50px;
      width: 82%;
      form {
        background: $background_color_2;
        h2 {
          font-size: 24px;
          padding: 0 0 20px;
          border-bottom: 2px solid $color_1;
          margin-bottom: 15px;
        }
        label {
          font-weight: 600;
          margin-bottom: 0;
        }
        .claim_btn {
          background-color: $background_color_4;
          color: $color_1;
          margin: 20px 0 0;
          padding: 15px;
          border-radius: 20px;
          min-width: 250px;
          width: 100%;
          font-size: 23px;
          font-weight: 600;
          &:hover {
            background-color: $background_color_1;
          }
        }
        span {
          a {
            font-size: 14px;
            font-weight: 600;
            color: $color_1;
            text-decoration: none;
          }
        }
        .form-check-label {
          font-size: 13px;
          a {
            color: $color_1;
          }
        }
      }
    }
  }

  /*================================================
	05 - CONTENT SECTION
	==================================================*/

  .content_section_one {
    padding: 40px 0 20px;
    h2 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    img {
      max-width: 100%;
    }
  }

  /*================================================
	06 - AGREEMENT SECTION
	==================================================*/

  .agreement_section {
    background-color: $background_color_1;
    padding: 30px 0;
    color: $color_1;
    h3 {
      text-align: center;
      font-size: 30px;
      margin-bottom: 20px;
    }
    .btn {
      background-color: $background_color_4;
      color: $color_1;
      margin: 20px 0 0;
      padding: 15px;
      border-radius: 20px;
      min-width: 250px;
      width: 50%;
      font-size: 23px;
      font-weight: 600;
      &:hover {
        background-color: $background_color_1;
      }
    }
  }

  /*================================================
	07 - FOOTER
	==================================================*/

  footer {
    background-color: #e5e5e5;
    padding: 30px 0;
    img {
      height: 80px;
      margin-bottom: 20px;
    }
    ul {
      list-style: none;
      display: block;
      padding: 0;
      li {
        display: inline-block;
        padding: 0 5px;
        border-right: 1px solid $color_2;
        a {
          color: $color_2;
          text-decoration: none;
          &:hover {
            color: $background_color_2;
          }
        }
        &:last-child {
          border: none;
        }
      }
    }
  }

  .trustpiolet {
    width: 50%;
    height: auto;
    margin: 30px auto;
    background-color: $color_1;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    padding: 13px 13px;
    border-radius: 5px;
    img {
      max-width: 80%;
      height: 30px;
      margin-bottom: 0;
    }
    p {
      margin: 0;
      line-height: 16px;
    }
    a {
      color: $color_2;
      text-decoration: none;
    }
  }

  /*================================================
	10 - THANKYOU PAGE
	==================================================*/
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .thankyou_page {
      flex-grow: 1;
      background-image: url(../img/tybg.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 50px;
      img {
        max-width: 100%;
        width: 15%;
        margin-bottom: 15px;
      }
      h1 {
        font-size: 46px;
        color: $color_1;
      }
      h4 {
        color: $color_1;
        margin-bottom: 20px;
        font-weight: 400;
      }
      .btn {
        min-width: 200px;
        padding: 15px;
        border-radius: 10px;
        font-size: 18px;
      }
    }

    .tyfooter {
      background-color: #393b3b;
      ul {
        list-style: none;
        text-align: right;
        li {
          color: $color_1;
        }
      }
      p {
        color: $color_1;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .trustpiolet {
      position: fixed;
      bottom: 10px;
      left: 10px;
      z-index: 999;
      width: 152px;
      padding: 5px;
    }
  }

  /*================================================
	11 - PRIVACY POLICY
	==================================================*/

  header {
    padding: 15px 0;
    border-bottom: 1px solid #333;
    margin-bottom: 15px;
    img {
      height: 80px;
    }
  }

  .privacy {
    ul,
    ol {
      background-color: $background_color_2;
      color: $color_1;
      padding: 15px 15px 15px 30px;
    }
    ul.bg2 {
      background-color: #767676;
    }
    h3 {
      font-weight: 400;
      font-size: 24px;
    }
    h1 {
      font-size: 30px;
    }
  }

  /*================================================
	09 - MEDIA QUERY
	==================================================*/
  $break-large: 1367px;
  $break-ipad-pro: 1024px;
  $break-min-ipad: 768px;
  $break-max-ipad: 991px;
  $break-min-tab: 577px;
  $break-max-tab: 767px;
  $break-mob: 576px;

  @media (min-width: 1200px) {
  }
  @media screen and (min-width: $break-large) {
    .bannersec {
      .form-section {
        width: 387px;
      }
    }
  }
  @media screen and (max-width: $break-ipad-pro) {
    .bannersec {
      h3 {
        width: 100%;
      }
    }
  }

  @media (min-width: $break-min-ipad) and (max-width: $break-max-ipad) {
    .wrapper {
      .thankyou_page {
        img {
          width: 28%;
          margin-bottom: 15px;
        }
      }
    }
  }

  @media (min-width: $break-min-tab) and (max-width: $break-max-tab) {
    .wrapper {
      .thankyou_page {
        img {
          width: 28%;
          margin-bottom: 15px;
        }
      }
    }
  }

  @media screen and (max-width: $break-min-ipad) {
    .bannersec {
      img {
        margin-bottom: 0;
        height: 80px;
      }
      h1 {
        padding-top: 10px;
        border-top: 1px solid rgb(0, 156, 218);
        font-size: 31px;
        margin: 20px 0;
      }
      h3 {
        padding: 0 0px;
      }
      .form-section {
        margin: 0 3%;
        width: 93%;
      }
    }

    .trustpiolet {
      width: 60%;
      height: auto;
      margin: 20px auto;
      position: inherit;
      background-color: $color_1;
      img {
        width: 60%;
        margin-bottom: 0;
        height: 30px;
      }
      p {
        margin: 0;
        line-height: 16px;
      }
    }
    .wrapper {
      .tyfooter {
        ul {
          text-align: center;
        }
      }
      .thankyou_page {
        img {
          width: 28%;
          margin-bottom: 15px;
        }
      }
    }
  }

  @media screen and (max-width: $break-mob) {
    .wrapper {
      .thankyou_page {
        img {
          width: 28%;
          margin-bottom: 15px;
        }
      }
    }
  }
  .error_msg {
    color: #ffffff;
    width: 100%;
    float: left;
    background: #f00;
    font-size: 13px;
    padding: 0px 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 10px;
    border-radius: 0;
  }
}

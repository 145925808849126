.AD_PI_V3 {
  /*
*******************************************
Template Name: Personal-Injury
Author: Nikhil
Date: 18-07-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the Personal-Injury, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - SECT
05 - content_section_part
06 - agreement_section
07 - FOOTER
08 - PRIVACY
09 - THANKYOU PAGE
10 - MEDIA QUERY

============================================
============================================

*/

  /*================================================
01 - Sass Variables
==================================================*/

  margin: 0;
  //font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Lato", sans-serif !important;

  $background_color_1: #fff;
  $background_color_2: #ddd;
  $background_color_3: #f8f8f8;
  $background_color_4: #0566a1;
  $background_color_5: #086eb5;

  $color_1: #fff;
  $color_2: #777777;
  $color_3: #445870;
  $color_4: #a7a7a7;
  $color_5: #000;
  $color_6: #006ebe;
  $color_7: #898c8d;
  $color_8: #03588b;

  $font-bold: bold;
  $font-w700: 700;
  $font-w600: 600;
  $font-w400: 400;

  $font_family_1: "Lato", sans-serif;

  $font_size_1: 16px;
  $font_size_2: 14px;
  $font_size_3: 44px;
  $font_size_4: 17px;
  $font_size_5: 15px;
  $font_size_6: 18px;
  $font_size_7: 12px;
  $font_size_8: 20px;
  $font_size_9: 28px;
  $font_size_10: 32px;
  $font_size_11: 30px;
  $font_size_12: 25px;
  $font_size_13: 24px;

  // ======border===========
  $border-style1: 2px solid #d9d9d9;
  $border-style2: 3px solid #001621;
  $border-style3: solid 5px #e8e8e8;

  /*================================================
02 - Sass Mixins
==================================================*/

  /*================================================
03 - BODY STYLES
==================================================*/

  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }

  .error_msg {
    color: $color_1;
    width: 100%;
    float: left;
    background: #f00;
    font-size: 13px;
    padding: 0px 5px;
    border-radius: 4px !important;
    -webkit-border-radius: 4px;
    margin-bottom: 10px;
  }

  .progress-bar {
    width: 12%;
    background: #015882 !important;
  }

  a {
    color: #007bff !important;
    cursor: pointer;
  }

  body {
    font-family: $font_family_1 !important;
  }

  .graysection {
    background-color: #ccc;
    z-index: 1;
    position: absolute;
    width: 100%;
  }

  .PI-1:before {
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    /*height: 450px;*/
    height: 60%;
    background-image: url(../img/banner1.webp);
    background-size: cover;
    content: "";
    z-index: -1;
  }

  header {
    .logo {
      height: 75px;
    }
  }

  /*================================================
04 - SECT
==================================================*/

  .SECT {
    margin-bottom: 30px;
    .pointsec {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      ul {
        padding: 0;
        li {
          font-size: 18px;
          background-image: url(../img/v-tick.png);
          background-repeat: no-repeat;
          left: 0;
          padding: 0 0 0 33px;
          list-style: none;
          margin: 10px 0 0;
        }
      }
    }
  }

  label {
    font-size: $font_size_8;
    line-height: 24px;
    margin-top: 15px;
    color: #4c4b4b;

    input[type="radio"] {
      display: none !important;
    }
  }

  .consent {
    p {
      font-size: $font_size_5;
    }
  }

  .form_part {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.37);
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.37);
    padding: 20px 0;
    width: 80%;
    margin: 20px auto;
    h1 {
      color: #015882 !important;
      font-size: 44px;
    }

    h2 {
      margin: 6px 0px 15px;
      font-size: 24px;
      color: #015882 !important;
    }

    .form-control {
      border: $border-style1;
      height: 55px;
      padding: 12px 30px 12px 15px;
      color: $color_3;
    }

    .border-class {
      border-radius: 4px 0 0 4px !important;
    }

    .form-group {
      width: 100%;
      margin-bottom: 7px;
      .para {
        font-size: 22px;
      }
      .input-group {
        margin-bottom: 14px;
      }
    }

    .label_contact {
      font-size: 17px;
    }

    #slide1,
    #slide2,
    #slide3,
    #slide4,
    #slide5,
    #slide6,
    #slide7 {
      .icon_option {
        background: #015882;
        border-bottom: $border-style2;
        font-size: $font_size_1;
        width: 100%;
        margin-top: 8px;
        cursor: pointer;
        padding: 20px 15px;

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .icon_option.active {
        border-color: #0e0314;
        background-color: #003149;
        z-index: 100;
        color: $color_1;
      }
      .nopad {
        padding: 20px 5px !important;
      }
      .custom-checkbox {
        display: none;
      }

      .radioa {
        background: #015882;
        font-size: 16px;
        width: 100%;
        margin-top: 8px;
        cursor: pointer;
        padding: 20px 15px;
        color: #fff;
        font-weight: 600;
      }
      .custom-checkbox:checked + label {
        background: #003149;
      }
    }

    .safe-text {
      font-size: $font_size_7;
      color: $color_4;
    }
    .my-bt {
      margin: 10px 0px !important;
      font-size: $font_size_9;
      padding: 12px 0;
      width: 100%;
      // -webkit-box-shadow: 0px 4px 0px 0px #015882;
      // -moz-box-shadow: 0px 4px 0px 0px #015882;
      // box-shadow: 0px 4px 0px 0px #015882;
      background-color: #015882;
    }
    .my-bts {
      margin: 10px 0px !important;
      font-size: $font_size_9;
      padding: 15px 0;
      width: 100%;
      background: #fbb53c;
    }
    .back_btn {
      font-size: $font_size_8;
      color: $color_6 !important;
      padding: 10px 0;
      cursor: pointer;
    }
    .loader_img {
      background-image: url(../img/loader.gif);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10%;
    }

    .form-check {
      display: block;
      padding-left: 0px;
      min-height: 1.5rem;
      margin-bottom: 0.125rem;
      .form-check-input {
        float: left;
        margin-left: -1.5em;
      }
      .form-check-label {
        a {
          color: #000 !important;
        }
      }
    }
  }

  .input-group-text {
    padding: 0px 5px !important;
    height: 55px !important;
    border-radius: 0px 5px 5px 0px !important;
    //top: 4px;
    position: relative;
  }

  /*================================================
05 - content_section_part
==================================================*/
  .content_section_part {
    padding: 40px 0 20px;
    h2 {
      font-size: 24px;
      img {
        max-width: 100%;
      }
    }
  }
  .content_section_part01 {
    padding: 40px 0 20px;
    background-color: #eae2ef;
    h3 {
      font-size: 24px;
      font-weight: 600;
    }
    p{
      font-size: 18px;
    }
    ol{
      padding: 0 0 0 20px;
      li{
        font-size: 18px;
      }
    }
  }  

  /*================================================
06 - agreement_section
==================================================*/

  .agreement_section {
    background-color: #015882;
    h3 {
      font-size: 30px;
    }

    .claim_btn {
      background-color: #ffb100;
      min-width: 250px;
      width: 50%;
      overflow: hidden;
      transition: 0.3s ease-in-out;
      border-radius: 0.3rem;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.22);
    }
    .claim_btn:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      transform: scale(0.95);
      background-color: #015882 !important;
      border-color: #fac639;
    }
  }

  /*================================================
07 - FOOTER
==================================================*/

  footer {
    font-family: "Poppins", sans-serif !important;
    line-height: 1.5;
    background-color: #e5e5e5;
    padding: 30px 0;
    color: #000 !important;
    img {
      height: 80px;
      margin-bottom: 20px;
    }
    ul {
      list-style: none;
      display: block;
      padding: 0;
      li {
        display: inline-block;
        border-right: 1px solid #000;
        a {
          color: #000 !important;
          text-decoration: none;
          cursor: pointer;
        }
        &:last-child {
          border: none;
        }
      }
    }
    p {
      font-size: 16px;
    }
    a {
      color: #fff !important;
    }
  }

  /*================================================
09 - THANKYOU PAGE
==================================================*/
  .wrapper {
    .thankyou_page {
      background-image: url(../img/tybg.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 50px;
      img {
        max-width: 100%;
      }
      h1 {
        font-size: 46px;
        color: #fff;
      }
      h4 {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 400;
      }
      .btn {
        min-width: 200px;
        padding: 15px;
        border-radius: 10px;
        font-size: 18px;
      }
    }

    .tyfooter {
      background-color: #393b3b;
      ul {
        list-style: none;
        text-align: right;
        li {
          color: #fff;
        }
      }
      p {
        color: #fff;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .trustpiolet {
      position: fixed;
      bottom: 10px;
      left: 10px;
      z-index: 999;
      width: 152px;
      padding: 5px;
    }
  }

  /*================================================
10 - MEDIA QUERY
==================================================*/

  $break-ipad-pro: 1024px;
  $break-min-ipad: 768px;
  $break-max-ipad: 990px;
  $break-min-tab: 577px;
  $break-max-tab: 767px;
  $break-mob: 576px;

  @media only screen and (max-width: 991px) {
    .firstslide {
      padding: 0px 16px;
    }
  }

  @media (min-width: $break-max-ipad) and (max-width: $break-ipad-pro) {
    .form_part form .form_cont .form-group {
      .icon_option {
        width: 90% !important;
        margin-right: 5px !important;
      }
    }
  }

  @media (min-width: $break-min-ipad) and (max-width: $break-max-ipad) {

    .form_part {
      width: 87% !important;
      h1 {
        font-size: $font_size_10;
      }
    }
  }

  @media (min-width: $break-min-tab) and (max-width: $break-max-tab) {
    header {
      .logo {
        height: 66px !important;
      }
    }
    .form_part {
      width: 95%;
      h1 {
        text-align: center;
        font-size: $font_size_11 !important;
      }
      .icon_option {
        width: 85% !important;
      }
    }
  }

  @media screen and (max-width: $break-mob) {
  
    .PI-1:before {
      position: absolute;
      top: 0px;
      content: "";
    }

    .start-but {
      width: 100px;
      height: 100px;
      margin-top: -55px;
      line-height: 18px;
    }

    .SECT {
      margin-bottom: 0px;
      &::before {
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 450px;
        z-index: -1;
      }
      h1 {
        font-size: 30px !important;
        padding: 10px 15px 5px;
      }

      h2 {
        font-size: 22px !important;
        line-height: 30px;
        padding: 2px 15px 49px;
        margin: 0px;
      }
      .pad0 {
        padding: 0px !important;
      }
    }

    header {
      .logo {
        height: 57px !important;
      } 
    }

    .form_part {
      width: 100% !important;
      border-radius: 0px;
      margin: 0px;
      .my-bt {
        font-size: $font_size_8;
      }

      form {
        .form_cont {
          .form-group {
            margin-bottom: 7px !important;
            .icon_option {
              width: 100% !important;
              margin-top: 0px !important;
              margin-bottom: 6px !important;
            }
            label {
              margin: 0 !important;
              padding: 15px 0;
              font-size: $font_size_8;
            }
          }

          .mobload {
            margin-bottom: 0px !important;
          }

          // .form-control {
          //   margin-bottom: 6px !important;
          // }
        }
      }

      .bord {
        padding: 5px 0 10px;
        .my-bt {
          font-size: $font_size_12;
          padding: 15px 0px;
        }
        .my-bts {
          font-size: $font_size_12 !important;
          padding: 15px 0px;
        }
      }

      .authorise {
        p {
          font-size: $font_size_7 !important;
        }
      }
      .consent {
        p {
          font-size: $font_size_7 !important;
        }
      }
    }

    footer {
      .fut {
        p {
          font-size: 12px !important;
        }
        img {
          height: 60px !important;
        }
      }
    }
  }
}

.modal-backdrop {
  background-color: #000 !important;
  opacity: 0.9 !important;
  .custom_modal {
    font-size: 15px;
    padding-right: 0px !important;
    .modal-content {
      border: solid #3b0458;
      border-radius: 0px;
      border-width: 5px 5px 25px !important;
      h2 {
        font-size: 28px;
      }
    }
  }
}

.nobg {
  background-color: transparent !important;
  border: 0 !important;
}

.bgno {
  background-color: transparent !important;
}
.show {
  display: block;
}
.hide {
  display: none;
}
#analayzeModal {
  font-family: "Titillium Web", sans-serif !important;
}

.custom-mdal-btn {
  margin: 10px 0 !important;
  font-size: 20px !important;
  width: 35%;
  background-color: #015882!important;
  border: 0px !important;
  border-bottom: 4px #003149 solid !important;
}
.bord-b{
  border-bottom: 5px solid #015882;
}
.pop-text{
  p{
    font-size: 16px;
    margin: 0px;
  }
}

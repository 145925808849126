

.complaints_procedure{ 
  font-family: "Arial",sans-serif !important;
    
    
 header{ 
    img{ 
        height: 60px;
    }
 }

 .privacy{ 
    padding: 30px 15px;

    h3 {
        font-weight: 400;
        font-size: 24px;
    }

    ol, .personal_injury .privacy ul {
        background-color: #03588b;
        color: #fff;
        padding: 15px 15px 15px 30px;
    }
 }

 footer { 
    font-family: "Poppins",sans-serif!important;
    line-height: 1.5;
    background-color: #e5e5e5;
    padding: 30px 0;
    color: #000!important;
    img {
      height: 80px;
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
    }
    ul {
      list-style: none;
      display: block;
      padding: 0;
      li {
        display: inline-block;
        padding: 0 5px;
        border-right: 1px solid #000;
        a {
          color: #000!important;
          text-decoration: none;
          cursor: pointer;
          font-size: 13px;
        }
      }
    }
  }
}

 @media screen and (max-width: 768px){ 
  header{ 
    .logo_1{ 
      height: 60px;
    }
  }

  .privacy{
    h1{
      font-size: 24px;
    }
    h3{
      font-size: 20px;
    }
    h6{
      font-size: 16px;
    }
    p{
      font-size: 14px;
    }
  }

  .footer_text{
    .footer_links{
      padding-left: 5px !important;
    }
   }
  }

  footer{ 
    .footer_logo{
      padding: 10px;
      img{ 
        height: 55px;
      }
    }
  }

  @media screen and (max-width: 556px){
    .footer_text{
      .footer_links{
        padding-left: 10px !important;
      }
     }
  }

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f8f9fa;
}

#complaints-procedure {
  font-family: Arial, sans-serif !important;
  line-height: 1.6;

  .complaints-a-tag {
    color: var(--bs-link-color) !important;
    text-decoration: underline !important;
  }

  .header-section {
    background-color: #015882;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  .content-section {
    padding: 20px;
    background-color: white;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .content-section h2 {
    color: #015882;
  }
  .footer {
    background-color: #343a40;
    padding: 10px 0;
    text-align: center;
    font-size: 0.9em;
    color: #f8f9fa;
  }

  .footer p {
    font-size: 0.9em;
    color: #f8f9fa !important;
  }
}

@import "custom";

#chat-bot {
  header {
    background: #eee;
  }

  .chat-section {
    width: 100%;
    // float: left;
    .chat-wrap {
      max-width: 650px;
      margin: 0 auto;
    }

    .chat-box-inner {
      position: relative;
      z-index: 5px;
      min-height: 400px;
      padding: 10px;
      overflow: hidden;
      .row-chart-message {
        font-family: Arial, Helvetica, sans-serif;

        @media only screen and (max-width: 375px) {
          min-height: 764px;
        }

        @media only screen and (min-width: 375px) and (max-width: 760px) {
          min-height: 680px;
        }

        @media only screen and (min-width: 761px) and (max-width: 1024px) {
          min-height: 580px;
        }

        @media only screen and (min-width: 1024px) {
          min-height: 600px;
        }

        .chater-prof {
          display: flex;
          align-items: flex-end;
          max-width: 90%;
          .text-msg {
            background: #e5e7eb;
            padding: 10px 15px;
            margin: 5px 0 0 0;
            display: inline-block;
            border-radius: 5px;
            font-size: 15px;
            cursor: pointer;
            &.btn-spc {
              padding: 20px 10px;
              label {
                font-size: 14px;
                font-weight: 600;
                color: #2f2f2f;
              }
            }
          }

          .phone {
            font-size: 16px;
            padding: 10px 20px;
          }
        }

        .msg-loader {
          img {
            height: 15px;
          }
        }
      }
      .supporter-dp {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ddd;
        display: block;
        margin-right: 10px;
        text-align: center;
        border: 1px solid #ddd;
        overflow: hidden;
        img {
          height: 38px;
        }
      }
      .sel-option {
        .action-btn {
          background: #3b82f6;
          padding: 15px 40px;
          color: #fff;
          -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
          animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          border-radius: 50px;
          font-weight: bold;
          &.answ-bnt {
            padding: 15px 20px;
          }
        }
      }

      .client-replay {
        text-align: right;
        margin-top: 10px;

        .client-replay-vpx {
          display: flex;
          justify-content: end;
          align-items: center;
          .dp-client {
            @extend .supporter-dp;
            order: 2;
            margin-right: 0;
            margin-left: 10px;
          }
          .sel-option {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 8px;
          }
        }
      }
    }
  }

  .list-items {
    li {
      font-size: 16px;
      position: relative;
      list-style-type: none;
      padding: 4px 0;
      &::before {
        content: "";
        background: url("../../../img/index-icon.png") no-repeat center center;
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        left: -40px;
        top: 0;
      }
    }
  }

  // .text-message-item {
  //   display: none !important;
  // }

  .slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
  .body-inner {
    // height: 100vh;
  }

  footer {
    float: left;
    width: 100%;
    padding-top: 150px;
    p {
      font-size: 13px;
    }
    a {
      color: $colorBlack;
      text-decoration: none;
    }
  }
  .text1 {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 18px;
    font-weight: bold;
    color: #4b5563;
  }
  .text2 {
    font-size: 28px;
    color: #4b5563;
  }
  .support-online {
    span {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: 0px 0px 1px 1px #0000001a;
      display: inline-block;
      position: relative;
      top: 3px;
      background: #22c55e;
    }

    .pulse {
      animation: pulse-animation 1.5s infinite;
    }

    @keyframes pulse-animation {
      0% {
        box-shadow: 0 0 0 0px rgba(162, 221, 122, 0.616);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
      }
    }
  }

  @media screen and (max-width: 760px) {
    .chat-section {
      .chat-box-inner {
        .sel-option {
          .action-btn {
            padding: 15px 30px;
          }
        }
      }
    }

    .text1 {
      font-size: 14px;
    }
    .text2 {
      font-size: 26px;
    }
  }

  @media screen and (max-width: 490px) {
    .text2 {
      font-size: 26px;
    }
    .list-items {
      li {
        padding-left: 35px;
        line-height: 20px;
        &::before {
          left: 0;
          background-size: 20px 20px;
        }
      }
    }
  }
}

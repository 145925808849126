

*, ::after, ::before {
    box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}
a{
	cursor: pointer;
	text-decoration: none !important;
	color: unset !important;
}

em {
    font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
  color: #333;
  background-color: #ffffff;


//=============== LOADING ========\\
.logo_1{
     margin-top: 30px;
}
.slide_bg1 {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;

    .form_sec {
        background-color: #f2f2f2;
        padding: 30px;
        margin-top: 30px;

        .slid_txt1 {
            font-size: 20px;
            font-weight: 700;
        }

        .agent_img{
            width: 15%;
        }

        .agent_head{
            font-size: 36px;
            font-weight: 900;
            color: #065b85;
        }

        .icon_option_num_a {
            width: 100%;
            letter-spacing: 4px;
            padding: 17px 7px;
        }

        .icon_option_num {
            width: 50%;
            margin: 0 auto 20px;
            font-size: 24px;
            padding: 20px 30px;
            text-align: center;
            background-size: 200% auto;
            color: #fff;
            border-radius: 10px;
            display: block;
            border: 0;
            font-weight: 600;
            box-shadow: 0 0 14px -7px #192ef0;
            background-image: linear-gradient(45deg,#4b3dff,#219ff9 51%,#2f82ff);
            cursor: pointer;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            animation: rocking 2s ease-out infinite;
            transition: .2s;
        }

        @keyframes rocking {
            0%{
                transform: rotate(0);
            }
            25%{
                transform: rotate(0);
            }
            50% {
                transform: rotate(2deg);
            }
            75% {
                transform: rotate(-2deg);
            }
            100%{
                transform: rotate(0);
            } 
        }
    }
}
}// BODY BRACKET 

@media screen and (max-width: 768px){ 
    .logo_1{
        height: 40px !important;
        margin-top: 15px !important;
    }
    .slide_bg1{
        .form_sec{
            margin-top: 20px !important;
            .slid_txt1{
                font-size: 18px !important;
            }
            .slid_txth{
                font-size: 18px !important;
            }
            .agent_head{
                font-size: 24px !important;
            }
            .icon_option_num{
                font-size: 16px !important;
                width: 100% !important;
            }
        }
    }
}
@media screen and (min-width: 2000px){ 
    .slide_bg1{ 
        .form_part{
          padding-bottom: 200px !important;
        }
      }
    .agent_footer{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }



.Idupload {
	.btn-camera {
		background: #0070ba !important;
		color: #fff !important;
		padding: 12px 10px !important;
		border: 0px;
		-moz-border-radius: 5px;
		-webkit-border-radius: 5px;
		border-radius: 5px;
		width: 100% !important;
		margin-bottom: 15px;
	}
	.custom-file-button {
		input[type="file"] {
			margin-left: -2px !important;
	
			&::-webkit-file-upload-button {
				display: none;
			}
			&::file-selector-button {
				display: none;
			}
		}
	
		.form-control{
			padding: 9px 10px;
			font-size: 15px;
			border-radius: 0.325rem;
		}
		.input-group-text{
			background-color: #0070ba;
			color: #fff;
			border-radius: 0.325rem;
		}
	}
	.btn-next {
		background: #257eba;
		color: #fff;
		font-weight: 400;
		font-size: 20px;
		padding: 15px 10px;
		width: 100%;
		cursor: pointer;
		border: 0;
		border-radius: 5px !important;
		margin: 15px 0;
	}
	.radio-box h4{
		color: rgba(9,62,177,0.68);
	}
	
}
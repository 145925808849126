// #AD_PI_CC_V5 {
/*
	*******************************************
	Template Name: claimsguru
	Author: Ramees R J
	Date:09-11-2023
	Template By: Vandalay Designs
	Copyright 2023-2024 Vandalay Business Solution

	* This file contains the styling for the Allegiant, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/

// 	============================================
// ==== T A B L E   O F   C O N T E N T S =====
// ============================================
// 01 - Sass Variables
// 02 - QUESTIONIRE BODY
// 03 - ANIMATION CLASSES
// 04 - ANIMATION
// 05 - MEDIA QUERY

/*================================================
01 - Sass Variables
==================================================*/
$background_color_2: #2f3996;

$color_1: #015882;

/*================================================
02 - QUESTIONIRE BODY 
==================================================*/

body {
  font-family: "Arial", sans-serif !important;
}

header {
  .logo_1 {
    height: 60px;
  }
}

.SECT {
  .form_part {
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
    h1 {
      color: $color_1 !important;
      font-size: 44px;
    }
    h4 {
      color: $color_1 !important;
    }
    p {
      color: #ff0000;
      text-align: center;
      font-size: 14px;
    }
    .arrow_gif {
      text-align: center;
      img {
        height: 20px;
      }
    }

    .icon_option,
    .my-bt {
      font-weight: 500 !important;
      background-color: #015882 !important;
      color: #ffffff !important;
      font-size: 24px;
      line-height: 3px;
      text-decoration: none;
      font-style: normal;
      padding-top: 37px !important;
      padding-bottom: 37px !important;
      border-width: nullpx !important;
      border-style: none !important;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      text-align: center;
      width: 100%;
      display: block;
    }
    label input[type="radio"] {
      display: none !important;
    }

    .form-control {
      border-radius: 5px 0 0 5px !important;
    }
    .input-group-text {
      border-radius: 0 5px 5px 0;
    }
    .qstn_1 {
      font-weight: 700;
      font-size: 30px;
      color: #4c4b4b;
      margin-bottom: 20px !important;
    }
    h5 {
      color: #4c4b4b;
    }

    .safe-text {
      color: #bab9b9;
    }

    .back_btn {
      font-size: 20px;
      color: #006ebe !important;
      padding: 10px 0;
      cursor: pointer;
    }
  }
}

.progress {
  .bg-grn {
    background-color: #015882;
  }
}

.footer_text {
  font-family: "Poppins", sans-serif !important;
  line-height: 1.5;
  background-color: #dfe5ea;
  padding: 30px 0;
  color: #000 !important;
  img {
    height: 80px;
    margin-bottom: 10px;
  }
}

footer {
  // font-family: "Poppins", sans-serif !important;
  line-height: 1.5;
  background-color: #e5e5e5;
  padding: 30px 0;
  color: #000 !important;

  img {
    height: 80px;
    margin-bottom: 10px;
  }
  p {
    font-size: 12px;
    color: #000 !important;
    // font-family: "Poppins", sans-serif !important;
  }
  ul {
    list-style: none;
    display: block;
    padding: 0;
    li {
      display: inline-block;
      padding: 0 5px !important;
      border-right: 1px solid #000 !important;
      a {
        color: #000 !important;
        text-decoration: none;
        cursor: pointer;
        font-size: 13px;
      }
    }
  }
}

.error_msg {
  color: #fff;
  width: 100%;
  float: left;
  background: red;
  font-size: 13px;
  padding: 0 5px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.9);
  h5 {
    font-weight: 300;
  }
  .custom-mdal-btn {
    margin: 10px 0 !important;
    font-size: 20px !important;
    width: 35%;
    background-color: #015882 !important;
    border: 0 !important;
    border-bottom: 4px solid #003149 !important;
    font-weight: 300;
    .slide-pad {
      h2 {
        font-weight: 100;
      }
    }
  }

  .bord-b {
    border-bottom: 5px solid #015882;
  }
}

.fade-in {
  -webkit-animation: fade-in 0.4s ease-in both;
  animation: fade-in 0.4s ease-in both;
}

// MEDIA QUERY

@media screen and (max-width: 768px) {
  header {
    .logo_1 {
      height: 60px;
    }
  }
  .SECT {
    .text-consent {
      font-size: 12px !important;
    }

    .form_part {
      width: 100%;
      margin: 0;
      padding-left: 12px;
      padding-right: 12px;
      h1 {
        color: #000000 !important;
      }
      .qstn_1 {
        font-size: 18px;
        line-height: 24px;
        margin-top: 15px;

        color: #4c4b4b;
      }
      .icon_option {
        font-size: 12px;
        width: 86%;
        margin: auto;
      }
      .form-control {
        border-radius: 5px 0 0 5px !important;
      }
      .input-group-text {
        border-radius: 0 5px 5px 0;
      }
    }
    h1 {
      font-size: 26px !important;
      padding: 10px 15px 8px !important;
    }
    h2 {
      font-size: 20px !important;
    }
    .sm-device-txt {
      width: 100%;
      padding: 10px 15px 25px 15px;
    }
  }

  footer {
    img {
      height: 60px;
    }
    p {
      font-size: 9px;
    }
    ul {
      li {
        a {
          font-size: 9px;
        }
      }
    }
  }
}
@media screen and (min-width: 2100px) {
  .SECT {
    .form_part {
      padding-bottom: 200px !important;
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.fade-in {
  -webkit-animation: fade-in 0.4s ease-in both;
  animation: fade-in 0.4s ease-in both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
// }
